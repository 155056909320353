.skills {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .skills {
    background-color: #e9e9e9;
    height: 100%;
  }
}

.skills-header {
  text-align: center;
}
.skills-header h1 {
  margin: 0;
  padding-top: 7rem;
  font-size: 35px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
}
.skills-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 6rem;
  width: 80%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="skills-container"] {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 2rem;
    width: 95%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.skills-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.skills-ball {
  height: 7rem;
  width: 7rem;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 1px 1px 10px 1px #bfbfbf;
  margin: 0.8rem 1rem;
  transition: ease-in-out 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.skills-ball:hover {
  transform: scale(1.3);
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="skills-ball"] {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 1px 1px 10px 1px #bfbfbf;
    margin: 0.8rem 1rem;
  }
}
.skills-ball img {
  height: 40%;
  margin: 0;
  padding: 10px 10px;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .skills-ball img {
    height: 40%;
    margin: 0;
    padding: 10px 10px;
  }
}
.skills-ball p {
  text-align: center;
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .skills-ball p {
    text-align: center;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
  }
}

.footer {
  height: 150px;
  background: linear-gradient(
    to right,
    #141e30,
    #243b55
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.footer p {
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.3rem;
  color: #fff;
  text-align: center;
  padding-top: 2rem;
  margin: 0;
  margin-bottom: 1rem;
}
.github-icon {
  height: 35px;
  width: auto;
  transition: ease-in-out 0.2s;
}
.github-icon:hover {
  transform: scale(1.2);
  cursor: pointer;
}
.github-icon path {
  fill: #fff;
}
.social-links {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}

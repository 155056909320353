@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;1,100&display=swap");

.landingpage {
  background-color: #e9e9e9;
  height: 100vh;
}
.welcome-background {
  /*background-color: #1a446f;*/
  background: linear-gradient(
    to right,
    #141e30,
    #243b55
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  height: 66%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
  position: relative;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="welcome-background"] {
    /*background-color: #1a446f;*/
    background: linear-gradient(
      to right,
      #141e30,
      #243b55
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    height: 66%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: visible;
    position: relative;
  }
}
.textwrapper {
  text-align: center;
}
.welcome {
  font-size: 120px;
  color: white;
  font-family: "Josefin Sans", sans-serif;
  text-shadow: 2px 2px 4px #000000;
  font-weight: 700;
  margin: 5rem 0 5rem 0;
  padding: 0;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="welcome"] {
    font-size: 60px;
    color: white;
    font-family: "Josefin Sans", sans-serif;
    text-shadow: 2px 2px 4px #000000;
    font-weight: 700;
    padding: 0;
  }
}
.logo {
  height: 80px;
}

.logo-wrapper {
  margin: 0 auto;
  position: absolute;
  bottom: -40px;
  left: calc(50% - 40px);
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="logo-wrapper"] {
    margin: 0 auto;
    position: absolute;
    bottom: -60px;
    left: calc(50% - 38px);
  }
}
.info-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0.3rem 0;
}
.info-box h3 {
  font-size: 30px;
  margin: 2rem 0 1.3rem 0;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .info-box h3 {
    font-size: 25px;
    margin: 2rem 0 1.3rem 0;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 700;
  }
}

.social-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 10rem;
  margin: 0 auto;
}
.social-link {
  height: 40px;
  transition: ease-in-out 0.2s;
}
.social-link:hover {
  transform: scale(1.2);
}
.learn-more {
  margin: 3rem 0 0 0;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
}
.arrow-down {
  height: 16px;
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(5px);
  }
}

.link-about:hover {
  cursor: pointer;
}

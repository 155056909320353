@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;1,100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;1,100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fixed {
    background-color: #c0c0c0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.landingpage {
  background-color: #e9e9e9;
  height: 100vh;
}
.welcome-background {
  /*background-color: #1a446f;*/
  background: linear-gradient(
    to right,
    #141e30,
    #243b55
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  height: 66%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
  position: relative;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="welcome-background"] {
    /*background-color: #1a446f;*/
    background: linear-gradient(
      to right,
      #141e30,
      #243b55
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    height: 66%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: visible;
    position: relative;
  }
}
.textwrapper {
  text-align: center;
}
.welcome {
  font-size: 120px;
  color: white;
  font-family: "Josefin Sans", sans-serif;
  text-shadow: 2px 2px 4px #000000;
  font-weight: 700;
  margin: 5rem 0 5rem 0;
  padding: 0;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="welcome"] {
    font-size: 60px;
    color: white;
    font-family: "Josefin Sans", sans-serif;
    text-shadow: 2px 2px 4px #000000;
    font-weight: 700;
    padding: 0;
  }
}
.logo {
  height: 80px;
}

.logo-wrapper {
  margin: 0 auto;
  position: absolute;
  bottom: -40px;
  left: calc(50% - 40px);
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="logo-wrapper"] {
    margin: 0 auto;
    position: absolute;
    bottom: -60px;
    left: calc(50% - 38px);
  }
}
.info-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0.3rem 0;
}
.info-box h3 {
  font-size: 30px;
  margin: 2rem 0 1.3rem 0;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .info-box h3 {
    font-size: 25px;
    margin: 2rem 0 1.3rem 0;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 700;
  }
}

.social-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 10rem;
  margin: 0 auto;
}
.social-link {
  height: 40px;
  transition: ease-in-out 0.2s;
}
.social-link:hover {
  transform: scale(1.2);
}
.learn-more {
  margin: 3rem 0 0 0;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
}
.arrow-down {
  height: 16px;
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(5px);
  }
}

.link-about:hover {
  cursor: pointer;
}

.about {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .about {
    background-color: #e9e9e9;
    height: 100%;
  }
}

.about-header {
  text-align: center;
}
.about-header h1 {
  margin: 0;
  padding-top: 7rem;
  font-size: 35px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
}

.about-container {
  margin: 0 15%;
  margin-top: 4em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="about-container"] {
    margin: 0 10%;
    margin-top: 4em;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
}
.img-cropper {
  width: 400px;
  height: 400px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="img-cropper"] {
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    float: right;
  }
}
.img {
  height: 120%;
  width: auto;
}
.text-container {
  text-align: left;
  width: 59%;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="text-container"] {
    text-align: justify;
    width: 100%;
  }
}
.text-container-h1 {
  margin: 0;
  padding: 0;
  font-size: 45px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="text-container-h1"] {
    margin: 0;
    margin-top: 1em;
    padding: 0;
    font-size: 30px;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 600;
    text-align: center;
  }
}

.text-container-p {
  margin: 1rem 1rem 1rem 0;
  padding: 0;
  font-size: 21px;
  font-family: "Josefin Sans", sans-serif;
  line-height: 25px;
  font-weight: 300;
  opacity: 0.9;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="text-container-p"] {
    margin: 1rem 0;
    padding: 0;
    font-size: 20px;

    font-family: "Josefin Sans", sans-serif;
    font-weight: 300;
    opacity: 0.9;
  }
}

.content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="content-container"] {
    flex-wrap: wrap-reverse;
  }
}

.navbar {
  background: linear-gradient(
    to right,
    #141e30,
    #243b55
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  height: 60px;
  -webkit-animation: fadein 0.3s; /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fadein 0.3s;
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.test {
  color: white;
  font-size: 20px;
}
.navbar-links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 45%;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="navbar-links"] {
    display: none;
  }
}

.about-link {
  color: white;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 800;
  font-size: 20px;
  margin-left: 1rem;
}
.skills-link {
  color: white;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 800;
  font-size: 20px;
  margin-left: 2rem;
}
.projects-link {
  padding-left: 3rem;
  margin-left: 2rem;

  color: white;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 800;
  font-size: 20px;
}
.contact-link {
  color: white;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 800;
  font-size: 20px;
}

.logo-navbar {
  height: 80px;
}

.logo-navbar-wrapper {
  margin: 0 auto;
  position: absolute;
  bottom: -40px;
  left: calc(50% - 40px);
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="logo-navbar-wrapper"] {
    margin: 0 auto;
    position: absolute;
    bottom: -40px;
    left: calc(50% - 38px);
  }
}

.drawer-wrapper {
  margin: 0.4rem 0;
  display: none;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="drawer-wrapper"] {
    margin: 0.4rem 0;
    display: flex;
  }
}

.skills {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .skills {
    background-color: #e9e9e9;
    height: 100%;
  }
}

.skills-header {
  text-align: center;
}
.skills-header h1 {
  margin: 0;
  padding-top: 7rem;
  font-size: 35px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
}
.skills-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 6rem;
  width: 80%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="skills-container"] {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 2rem;
    width: 95%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.skills-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.skills-ball {
  height: 7rem;
  width: 7rem;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 1px 1px 10px 1px #bfbfbf;
  margin: 0.8rem 1rem;
  transition: ease-in-out 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.skills-ball:hover {
  transform: scale(1.3);
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="skills-ball"] {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 1px 1px 10px 1px #bfbfbf;
    margin: 0.8rem 1rem;
  }
}
.skills-ball img {
  height: 40%;
  margin: 0;
  padding: 10px 10px;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .skills-ball img {
    height: 40%;
    margin: 0;
    padding: 10px 10px;
  }
}
.skills-ball p {
  text-align: center;
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .skills-ball p {
    text-align: center;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
  }
}

.projects {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .projects {
    background-color: #e9e9e9;
    height: 100%;
  }
}

.projects-header {
  text-align: center;
}
.projects-header h1 {
  margin: 0;
  padding-top: 7rem;
  font-size: 35px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
}

.project-box {
  width: 450px;
  height: 280px;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem 0;
  position: relative;
}

.project-image {
  height: 100%;
  width: auto;
}

.projects-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 6rem;
  width: 80%;
  justify-content: center;
  text-align: center;
}

.projects-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #0c0c0d;
}
.project-box:hover .overlay {
  opacity: 0.95;
}
.text {
  color: white;
  font-size: 15px;
  position: absolute;
  top: 45%;
  left: 50%;
  text-align: left;
  width: 70%;
  transform: translate(-50%, -50%);
  font-family: "Josefin Sans", sans-serif;
}
.text h1 {
  font-family: "Josefin Sans", sans-serif;
  font-size: 2rem;
}

.contact {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .contact {
    background-color: #e9e9e9;
    height: 100%;
  }
}
.contact-header {
  text-align: center;
}
.contact-header h1 {
  margin: 0;
  padding-top: 7rem;
  font-size: 35px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
}

.contact-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 25rem;
  margin: 0 auto;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .contact-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: 0 auto;
  }
}
.contact-info p {
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.1rem;
  text-align: justify;
}
.plane {
  height: 20px;
  width: 20px;
  margin: 0;
  padding: 0;
}

.footer {
  height: 150px;
  background: linear-gradient(
    to right,
    #141e30,
    #243b55
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.footer p {
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.3rem;
  color: #fff;
  text-align: center;
  padding-top: 2rem;
  margin: 0;
  margin-bottom: 1rem;
}
.github-icon {
  height: 35px;
  width: auto;
  transition: ease-in-out 0.2s;
}
.github-icon:hover {
  transform: scale(1.2);
  cursor: pointer;
}
.github-icon path {
  fill: #fff;
}
.social-links {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}


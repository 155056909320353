@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;1,100&display=swap");

.about {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .about {
    background-color: #e9e9e9;
    height: 100%;
  }
}

.about-header {
  text-align: center;
}
.about-header h1 {
  margin: 0;
  padding-top: 7rem;
  font-size: 35px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
}

.about-container {
  margin: 0 15%;
  margin-top: 4em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="about-container"] {
    margin: 0 10%;
    margin-top: 4em;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
}
.img-cropper {
  width: 400px;
  height: 400px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="img-cropper"] {
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    float: right;
  }
}
.img {
  height: 120%;
  width: auto;
}
.text-container {
  text-align: left;
  width: 59%;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="text-container"] {
    text-align: justify;
    width: 100%;
  }
}
.text-container-h1 {
  margin: 0;
  padding: 0;
  font-size: 45px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="text-container-h1"] {
    margin: 0;
    margin-top: 1em;
    padding: 0;
    font-size: 30px;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 600;
    text-align: center;
  }
}

.text-container-p {
  margin: 1rem 1rem 1rem 0;
  padding: 0;
  font-size: 21px;
  font-family: "Josefin Sans", sans-serif;
  line-height: 25px;
  font-weight: 300;
  opacity: 0.9;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="text-container-p"] {
    margin: 1rem 0;
    padding: 0;
    font-size: 20px;

    font-family: "Josefin Sans", sans-serif;
    font-weight: 300;
    opacity: 0.9;
  }
}

.content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="content-container"] {
    flex-wrap: wrap-reverse;
  }
}

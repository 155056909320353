.contact {
  background-color: #e9e9e9;
  min-height: 100vh;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .contact {
    background-color: #e9e9e9;
    height: 100%;
  }
}
.contact-header {
  text-align: center;
}
.contact-header h1 {
  margin: 0;
  padding-top: 7rem;
  font-size: 35px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
}

.contact-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 25rem;
  margin: 0 auto;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .contact-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: 0 auto;
  }
}
.contact-info p {
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.1rem;
  text-align: justify;
}
.plane {
  height: 20px;
  width: 20px;
  margin: 0;
  padding: 0;
}
